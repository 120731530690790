import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTranslation } from 'react-i18next'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { BasicLayers, FeatureCollectionLayers, GeojsonLayers, WMSLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { ISectionTile } from 'apps/placeme/src/features/Analyse/slice/@types/ISectionTile'
import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import { CommonInvestmentWithCategory } from '../components/Others'
import { CommonInvestment, OthersData } from '../@types/IInvestmentsTileData'
import { investmentTypes, othersInvestmentsTypes } from '../utils/constants'
import { getInvestmentIcon, getLayers } from '../utils/functions'
import { ObjectWrapper, ScrollWrapper, TitleWithLegend } from '../Investments.styles'
import InvestmentsList from '../components/InvestmentsList'

interface UseOtherInvestmentsProps {
  investments?: OthersData
  compared?: boolean
  tileId: string
  sectionTilesData: ISectionTile[]
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useOtherInvestments({
  investments, tileId, sectionTilesData, compared = false,
}: UseOtherInvestmentsProps) {
  const { t } = useTranslation()
  const { isPrinting } = useCheckIfPrinting()
  const [selectedCategories, setSelectedCategories] = useState<ResourceWithId[]>([])
  const [filteredListWithCategories, setFilteredListWithCategories] = useState<CommonInvestmentWithCategory[]>([])

  const otherInvestmentsDataExists = useMemo(() => {
    if (!investments) return
    return !Object.entries(investments).every(investmentList => !investmentList[1].length)
  }, [investments])

  const categories = useMemo(() => {
    if (!otherInvestmentsDataExists) return []
    return Object.keys(investments!).map(category => ({
      id: category,
      content: t(`placeme.investments_tile.category.${category}`),
    })) as ResourceWithId[]
  }, [investments, otherInvestmentsDataExists])

  const selectedInvestments = useMemo(() => {
    if (!otherInvestmentsDataExists) return
    return selectedCategories.reduce((acc, curr) => {
      const investment = investments![curr.id as keyof typeof othersInvestmentsTypes]
      if (!investment) {
        return {
          ...acc,
        }
      }
      const sortedInvestments = [...investment].sort((a, b) => a.distance - b.distance)
      return {
        ...acc,
        [curr.id]: sortedInvestments,
      }
    }, {})
  }, [selectedCategories, investments, otherInvestmentsDataExists])

  const selectedInvestmentsWithCategories = useMemo(() => {
    if (!otherInvestmentsDataExists) return
    const list: CommonInvestmentWithCategory[] = []
    Object.entries(selectedInvestments as OthersData).map(investmentList => (
      investmentList[1].map((investment: CommonInvestment) => (
        list.push({
          ...investment,
          category: investmentList[0],
        })
      ))
    ))
    return list
  }, [selectedInvestments, otherInvestmentsDataExists])

  const filteredInvestments = useMemo((): OthersData => {
    if (!otherInvestmentsDataExists) return {}
    return filteredListWithCategories.reduce((acc, curr): OthersData => {
      const newObj = {
        ...curr,
        volume: Number(curr.volume),
      }
      return {
        ...acc,
        [curr.category]: [
          ...acc[curr.category as keyof typeof othersInvestmentsTypes] ?? [],
          newObj,
        ],
      } }, {} as OthersData)
  }, [filteredListWithCategories, otherInvestmentsDataExists])

  const getMapLayers = useCallback((investmentsData: OthersData) => {
    const layers: unknown[] = []
    Object.entries(investmentsData).forEach(investment => {
      const dataObj = {
        list: investment[1],
      }
      const layer = getLayers({
        tileId,
        sectionTilesData,
        data: dataObj,
        iconType: investmentTypes[investment[0] as keyof typeof investmentTypes],
        compared,
      })
      if (!layer) return
      layers.push(...layer)
    })
    return layers as (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[] | undefined
  }, [filteredInvestments, tileId, sectionTilesData, compared])

  const otherInvestmentsLayers = useMemo(() => (
    getMapLayers(filteredInvestments)
  ), [investments, getMapLayers, otherInvestmentsDataExists])

  const selectedInvestmentsTables = useMemo(() => {
    if (!otherInvestmentsDataExists) return

    return Object.entries(filteredInvestments).map((investment) => (
      <ObjectWrapper key={investment[0]}>
        <TitleWithLegend>
          <img
            alt=''
            src={getInvestmentIcon(investment[0])}
          />
          {t(`placeme.investments_tile.category.${investment[0]}`)}
        </TitleWithLegend>
        <ScrollWrapper $isPrinting={isPrinting}>
          <PerfectScrollbar className='scroll'>
            <InvestmentsList commonInvestments={investment[1] as CommonInvestment[]} />
          </PerfectScrollbar>
        </ScrollWrapper>
      </ObjectWrapper>
    ))
  }, [filteredInvestments, otherInvestmentsDataExists])

  useEffect(() => setSelectedCategories(categories), [categories])

  return {
    otherInvestmentsDataExists,
    categories,
    selectedCategories,
    setSelectedCategories,
    selectedInvestmentsWithCategories,
    setFilteredListWithCategories,
    selectedInvestmentsTables,
    otherInvestmentsLayers,
    filteredInvestments,
  }
}

export default useOtherInvestments
