import React, { useContext, useEffect, useState } from 'react'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import {
  BarChart,
  TileFormWrapper,
  TileFormSpan,
  TileFormSection,
  TileFormSectionTitle,
  TileFormRow,
  TileFormColumn,
  DoughnutChart,
  TileFormRowWithData,
  Price,
  Tooltip,
} from '@dataplace.ai/ui-components/atoms'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '@dataplace.ai/features'
import { useCheckForMobileDevice } from '@dataplace.ai/ui-components/themes'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { ComparedLocationBean } from '@dataplace.ai/ui-components/molecules'
import { IDemographyTileData } from './@types/IDemographyTileData'
import { fetchWorkspaceUsageValue } from '../../../../../slice/analysisSlice'
import { useAppDispatch } from '../../../../../../../redux/hooks'

const Banner = styled.div<{comparedLocation?: boolean, $backgroundColor?: string}>(({
  theme, comparedLocation, $backgroundColor,
}) => {
  const {
    palette, typography, corners,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: ${corners.default.borderRadius};
    justify-content: center;
    color: ${palette.light.white};
    background-color: ${$backgroundColor ?? palette.product.location.medium};
    padding: 1rem;
    width: 6rem;
    margin: 0;

    > span:first-child {
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
    }

    > span:last-child {
      > span {
        color: ${palette.light.white};
      }
    }

    ${comparedLocation && css`
      margin-top: 5px;
      margin-left:0;
    `}
  `
})

const StyledParagraph = styled.p`
  text-align: center;
`

const LegendWrapper = styled.div(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
  display: flex;
  justify-content: space-between;
  width: 90%;

  >div{
    display: flex;
    flex-direction: column;
    margin: 16px 0;
    color: ${palette.dark.normal};

    >div{
      display: flex;
      margin-top: 5px;
      
      >span{
        width: 10px;
        height: 20px;
        background: #ADAAE3;
        border-radius: 2px;
        margin-right: 5px;

        :nth-of-type(2){
          margin-left: 20px;
        }
      }
      >p{
        font-size: ${typography.tiny.pt_12_regular.fontSize};
        font-weight: ${typography.tiny.pt_12_regular.fontWeight};
        line-height: ${typography.tiny.pt_12_regular.lineHeight};
      }
    }
  }

`
})

export const DemographyTile: React.FC<{data: IDemographyTileData}> = ({ data }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isMobileDevice = useCheckForMobileDevice()
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)
  const { value } = useSelector((state: RootState) => state.location)
  const { comparedLocation } = useSelector((state: RootState) => state.analysis)

  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue())
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData.user?.getIdToken()?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  const sortedAgeRange = data?.value?.ageRange?.length
    ? data?.value?.ageRange?.slice().sort((a, b) => (a.order > b.order ? 1 : -1))
    : []

  const sortedAgeRangeComparedLocation = data?.value?.comparedLocation?.ageRange?.length
    ? data?.value?.comparedLocation?.ageRange?.slice().sort((a, b) => (a.order > b.order ? 1 : -1))
    : []

  function getDemoValue(...args: (number | undefined)[]) {
    let finalVal = 0
    args.forEach(num => {
      if (num !== undefined) {
        finalVal = num
      }
    })
    return parseFloat((finalVal * 100).toFixed(1))
  }

  return !data || data?.loading
    ? (<Loader />)
    : (
      <TileFormWrapper>
        <TileFormSpan>
          {t('placeme.demography_tile.form_span_1')}
        </TileFormSpan>
        {/* age */}
        <TileFormSection>
          <TileFormSectionTitle>{t('placeme.demography_tile.section_title_1')}</TileFormSectionTitle>
          <TileFormRow width='100%'>
            <TileFormColumn
              align='start'
              width='60%'
            >
              <TileFormRowWithData>
                <span>{ t('placeme.demography_tile.row_with_data_1_span_1')}</span>
                <span>{`${getDemoValue(data?.value?.workingAgeStructure?.above65, data?.value?.workingAgeStructure?.above60)}%`}</span>
                {(data?.value?.comparedLocation?.workingAgeStructure?.above65
                || data?.value?.comparedLocation?.workingAgeStructure?.above60) && (
                  <ComparedLocationBean
                    comparedAddress={comparedLocation?.location?.address}
                    comparedValue={`${getDemoValue(data?.value?.comparedLocation?.workingAgeStructure?.above65, data?.value?.comparedLocation?.workingAgeStructure?.above60)}%`}
                    difference={data?.value?.comparedLocation?.workingAgeStructure?.above65Diff
                      || data?.value?.comparedLocation?.workingAgeStructure?.above60Diff}
                    mainAddress={value?.address}
                    mainValue={`${getDemoValue(data?.value?.workingAgeStructure?.above65, data?.value?.workingAgeStructure?.above60)}%`}
                  />
                )}
              </TileFormRowWithData>
              <TileFormSpan>
                {t('placeme.demography_tile.form_span_2_normal_1')}
                {data?.value?.workingAgeStructure?.above65
                  ? <strong>{t('placeme.demography_tile.form_span_2_strong_1')}</strong>
                  : <strong>{t('placeme.demography_tile.form_span_2_strong_1.foreign')}</strong>}
                {t('placeme.demography_tile.form_span_2_normal_2')}
              </TileFormSpan>
              <TileFormRowWithData>
                <span>{t('placeme.demography_tile.row_with_data_2_span_1')}</span>
                <span>{`${getDemoValue(data?.value?.workingAgeStructure?.under19, data?.value?.workingAgeStructure?.under18, data?.value?.workingAgeStructure?.under15)}%`}</span>
                {(data?.value?.comparedLocation?.workingAgeStructure?.under19
                || data?.value?.comparedLocation?.workingAgeStructure?.under18
                || data?.value?.comparedLocation?.workingAgeStructure?.under15) && (
                  <ComparedLocationBean
                    comparedAddress={comparedLocation?.location?.address}
                    comparedValue={`${getDemoValue(data?.value?.comparedLocation?.workingAgeStructure?.under19, data?.value?.comparedLocation?.workingAgeStructure?.under18, data?.value?.comparedLocation?.workingAgeStructure?.under15)}%`}
                    difference={data?.value?.comparedLocation?.workingAgeStructure?.under19Diff
                      || data?.value?.comparedLocation?.workingAgeStructure?.under18Diff
                      || data?.value?.comparedLocation?.workingAgeStructure?.under15Diff}
                    mainAddress={value?.address}
                    mainValue={`${getDemoValue(data?.value?.workingAgeStructure?.under19, data?.value?.workingAgeStructure?.under18, data?.value?.workingAgeStructure?.under15)}%`}
                  />
                )}
              </TileFormRowWithData>
              <TileFormSpan>
                {t('placeme.demography_tile.form_span_3_normal_1')}
                {(data?.value?.workingAgeStructure?.under19 || data?.value?.workingAgeStructure?.under18)
                  ? <strong>{t('placeme.demography_tile.form_span_3_strong_1')}</strong>
                  : <strong>{t('placeme.demography_tile.form_span_3_strong_1.foreign')}</strong>}
                {t('placeme.demography_tile.form_span_3_normal_2')}
              </TileFormSpan>
            </TileFormColumn>
            <TileFormColumn
              align='center'
              comparedLocation={!!data?.value?.comparedLocation?.averageAge}
              width='40%'
            >
              <Tooltip
                content={value?.address || ''}
                display='flex'
                gap='0.5rem'
                position='top left'
              >
                <Banner>
                  <StyledParagraph>{t('placeme.demography_tile.banner')}</StyledParagraph>
                  <Price
                    currency={t('generic.years')}
                    digits={0}
                    value={data?.value?.averageAge}
                  />
                </Banner>
                {data?.value?.voivodeshipAverageAge
                  ? (
                    <Banner $backgroundColor={defaultTheme.palette.product.location.main}>
                      <StyledParagraph>{t('placeme.demography_tile.voivodeship_average')}</StyledParagraph>
                      <Price
                        currency={t('generic.years')}
                        digits={0}
                        value={data?.value?.voivodeshipAverageAge}
                      />
                    </Banner>
                  )
                  : null }
              </Tooltip>
              {data?.value?.comparedLocation?.averageAge && (
                <Tooltip
                  content={comparedLocation?.location?.address || ''}
                  display='flex'
                  gap='0.5rem'
                  position='top left'
                >
                  <Banner
                    $backgroundColor={defaultTheme.palette.results.yellow.light}
                    comparedLocation
                  >
                    <StyledParagraph>{t('placeme.demography_tile.banner')}</StyledParagraph>
                    <Price
                      currency={t('generic.years')}
                      digits={0}
                      value={data?.value?.comparedLocation?.averageAge}
                    />
                  </Banner>
                  {data?.value?.comparedLocation?.voivodeshipAverageAge
                    ? (
                      <Banner
                        $backgroundColor={defaultTheme.palette.gold.normal}
                        comparedLocation
                      >
                        <StyledParagraph>{t('placeme.demography_tile.voivodeship_average')}</StyledParagraph>
                        <Price
                          currency={t('generic.years')}
                          digits={0}
                          value={data?.value?.comparedLocation?.voivodeshipAverageAge}
                        />
                      </Banner>
                    )
                    : null }
                </Tooltip>
              ) }
            </TileFormColumn>
          </TileFormRow>
        </TileFormSection>
        <TileFormSection>
          <TileFormSectionTitle>{t('placeme.demography_tile.section_title_2')}</TileFormSectionTitle>
          <TileFormRow width='100%'>
            <TileFormColumn
              align='start'
              width={!isMobileDevice ? '70%' : '100%'}
            >
              <TileFormRowWithData legend='#362FB7'>
                <span>{t('placeme.demography_tile.row_with_data_3_span_1')}</span>
                <span>{`${parseFloat((data?.value?.sexStructure?.f * 100).toFixed(1))}%`}</span>
                {data?.value?.comparedLocation?.sexStructure?.f && (
                  <ComparedLocationBean
                    comparedAddress={comparedLocation?.location?.address}
                    comparedValue={`${parseFloat((data?.value?.comparedLocation?.sexStructure?.f * 100).toFixed(1))}%`}
                    difference={data?.value?.comparedLocation?.sexStructure?.fDiff}
                    mainAddress={value?.address}
                    mainValue={`${parseFloat((data?.value?.sexStructure?.f * 100).toFixed(1))}%`}
                  />
                )}
              </TileFormRowWithData>
              <TileFormSpan>
                {t('placeme.demography_tile.form_span_4')}
              </TileFormSpan>
              <TileFormRowWithData legend='#7E7AD2'>
                <span>{t('placeme.demography_tile.row_with_data_4_span_1')}</span>
                <span>{`${parseFloat((data?.value?.sexStructure?.m * 100).toFixed(1))}%`}</span>
                {data?.value?.comparedLocation?.sexStructure?.m && (
                  <ComparedLocationBean
                    comparedAddress={comparedLocation?.location?.address}
                    comparedValue={`${parseFloat((data?.value?.comparedLocation?.sexStructure?.m * 100).toFixed(1))}%`}
                    difference={data?.value?.comparedLocation?.sexStructure?.mDiff}
                    mainAddress={value?.address}
                    mainValue={`${parseFloat((data?.value?.sexStructure?.m * 100).toFixed(1))}%`}
                  />
                )}
              </TileFormRowWithData>
              <TileFormSpan>
                {t('placeme.demography_tile.form_span_5')}
              </TileFormSpan>
            </TileFormColumn>
            <TileFormColumn width={!isMobileDevice ? '30%' : '100%'}>
              <DoughnutChart
                data={[data?.value?.sexStructure?.f * 100, data?.value?.sexStructure?.m * 100]
                  .map(number => parseFloat(number.toFixed(1)))}
                innerLabels
                labels={[t('placeme.demography_tile.doughnut_chart.women'), t('placeme.demography_tile.doughnut_chart.men')]}
                valueType='percentages'
                width={150}
              />
            </TileFormColumn>
          </TileFormRow>
        </TileFormSection>
        <TileFormSection className={data?.value?.comparedLocation?.ageRange ? 'page-break-before' : ''}>
          <TileFormSectionTitle>{value?.country !== 'NL' ? t('placeme.demography_tile.section_title_3') : t('placeme.demography_tile.section_title_3.netherlands')}</TileFormSectionTitle>
          {data?.value?.comparedLocation?.ageRange && (
            <LegendWrapper>
              <div>
                <TileFormSpan>
                  {t('placeme.demography_tile.bar_chart.legend.location')}
                  {' '}
                  <strong>{value?.address}</strong>
                </TileFormSpan>
                { value?.country !== 'NL'
                  ? (
                    <div>
                      <span
                        style={{
                          backgroundColor: '#362FB7',
                        }}
                      />
                      <p>{t('placeme.demography_tile.bar_chart_dataset_label_1')}</p>
                      <span
                        style={{
                          backgroundColor: '#ADAAE3',
                        }}
                      />
                      <p>{t('placeme.demography_tile.bar_chart_dataset_label_2')}</p>
                    </div>
                  )
                  : (
                    <div>
                      <span
                        style={{
                          backgroundColor: '#362FB7',
                        }}
                      />
                      <p>{t('placeme.demography_tile.bar_chart_dataset_label_1.netherlands')}</p>
                    </div>
                  )}
              </div>
              <div>
                <TileFormSpan>
                  {t('placeme.demography_tile.bar_chart.legend.location')}
                  {' '}
                  <strong>{comparedLocation?.location?.address}</strong>
                </TileFormSpan>
                {value?.country !== 'NL'
                  ? (
                    <div>
                      <span
                        style={{
                          backgroundColor: '#D8A800',
                        }}
                      />
                      <p>{t('placeme.demography_tile.bar_chart_dataset_label_1')}</p>
                      <span
                        style={{
                          backgroundColor: '#F5D564',
                        }}
                      />
                      <p>{t('placeme.demography_tile.bar_chart_dataset_label_2')}</p>
                    </div>
                  )
                  : (
                    <div>
                      <span
                        style={{
                          backgroundColor: '#ADAAE3',
                        }}
                      />
                      <p>{t('placeme.demography_tile.bar_chart_dataset_label_1.netherlands')}</p>
                    </div>
                  )}
              </div>
            </LegendWrapper>
          )}
          <BarChart
            axisLabels={{
              xAxis: t('placeme.demography_tile.bar_chart_axis_x_label'),
            }}
            beginsAtZero
            dataset={value?.country !== 'NL'
              ? (
                data?.value?.comparedLocation?.ageRange
                  ? [
                    {
                      label: t('placeme.demography_tile.bar_chart_dataset_label_1'),
                      data: sortedAgeRange.map(ar => (ar.f)),
                    },
                    {
                      label: t('placeme.demography_tile.bar_chart_dataset_label_2'),
                      data: sortedAgeRange.map(ar => (ar.m)),
                    },
                    {
                      label: t('placeme.demography_tile.bar_chart_dataset_label_3'),
                      data: sortedAgeRangeComparedLocation.map(ar => (ar.f)),
                    },
                    {
                      label: t('placeme.demography_tile.bar_chart_dataset_label_4'),
                      data: sortedAgeRangeComparedLocation.map(ar => (ar.m)),
                    },
                  ]
                  : [
                    {
                      label: t('placeme.demography_tile.bar_chart_dataset_label_1'),
                      data: sortedAgeRange.map(ar => (ar.f)),
                    },
                    {
                      label: t('placeme.demography_tile.bar_chart_dataset_label_2'),
                      data: sortedAgeRange.map(ar => (ar.m)),
                    },
                  ]
              )
              : (data?.value?.comparedLocation?.ageRange
                ? [
                  {
                    label: t('placeme.demography_tile.bar_chart_dataset_label_1.netherlands'),
                    data: sortedAgeRange.map(ar => (ar.t)),
                  },
                  {
                    label: t('placeme.demography_tile.bar_chart_dataset_label_1.netherlands_2'),
                    data: sortedAgeRangeComparedLocation.map(ar => (ar.t)),
                  },
                ]
                : [
                  {
                    label: t('placeme.demography_tile.bar_chart_dataset_label_1.netherlands'),
                    data: sortedAgeRange.map(ar => (ar.t)),
                  },
                ])}
            labels={sortedAgeRange.map(ar => ar.label)}
            precision
          />
        </TileFormSection>
      </TileFormWrapper>
    )
}

export default DemographyTile
